<template>
  <div class="coupon-exchange-edit">
    <div v-loading="loading" />
    <div class="card-container form-wrapper">
      <p class="card-title">{{ `${couponId ? '編輯' : '新增'}兌換券` }}</p>
      <BaseElForm
        ref="formRef"
        label-position="top"
        :model="formData"
        :rules="formRules"
      >
        <BaseElFormItem label="名稱" prop="name" required>
          <BaseElInput v-model="formData.name" placeholder="兌換券名稱" />
        </BaseElFormItem>
        <BaseElFormItem
          label="圖片"
          prop="image"
          class="form-relative-label others"
        >
          <UploadButton
            cyUploadBtn="coupon-img-upload-btn"
            cyUploadedImg="coupon-img-uploaded"
            :img="formData.avatar"
            :isAvatar="true"
            @change="loadImg"
          />
        </BaseElFormItem>
        <BaseElFormItem label="到期方式" prop="expType" required>
          <p v-if="couponId" class="disable-item">
            {{
              expTypeConfig.find(({ value }) => value === formData.expType)
                .label
            }}
          </p>
          <BaseElSelect
            v-if="!couponId"
            v-model="formData.expType"
            @change="removeOption"
          >
            <BaseElSelectOption
              v-for="(item, index) in expTypeConfig"
              :key="index"
              :value="item.value"
              :label="item.label"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem
          v-if="formData.expType === 'expDay'"
          label="領取後可使用天數"
          prop="expDay"
          required
        >
          <p v-if="couponId" class="disable-item">{{ formData.expDay }}天</p>
          <BaseElInput
            v-if="!couponId"
            v-model="formData.expDay"
            placeholder="請輸入天數"
          />
        </BaseElFormItem>
        <BaseElFormItem label="點選「前往兌換」後，立即核銷兌換券">
          <BaseElSwitch
            v-model="formData.systemRedeem"
            active-text="開啟"
            inactive-text="關閉"
          />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="formData.expType === 'expAt'"
          label="特定截止日期"
          prop="expAt"
          required
        >
          <p v-if="couponId" class="disable-item">
            {{ formatDateTime(formData.expAt) }}
          </p>
          <el-date-picker
            v-if="!couponId"
            v-model="formData.expAt"
            editable
            type="datetime"
            placeholder="選擇日期時間"
            format="yyyy-MM-dd HH:mm:ss"
          />
        </BaseElFormItem>
        <BaseElFormItem label="注意事項" prop="notice" required>
          <quillEditor v-model="formData.notice" :options="editorOption" />
        </BaseElFormItem>
      </BaseElForm>

      <ImageCropper
        v-if="uploadDialog"
        :image="formData.img"
        @uploaded="getImage"
        @close="uploadDialog = false"
      />
      <PageFixedFooter @cancel="onCancel" @confirm="onSubmit" />
    </div>
    <CouponExchangeDialog
      v-if="showDialog.show"
      :couponId="showDialog.couponId"
      @close="
        showDialog.show = false
        router.push({ name: 'CouponExchangeSetting' })
      "
    />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import CouponExchangeDialog from './components/CouponExchangeDialog.vue'
import { noEmptyRules, isDigitRules } from '@/validation'
import { formatDate } from '@/utils/date'
import formUtils from '@/utils/form'
import store from '@/store'

import { useEditor } from '@/use/editor'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import {
  CreateCouponExchange,
  UpdateCouponExchange,
  FindCouponExchange,
} from '@/api/coupon'

export default defineComponent({
  name: 'CouponExchangeEdit',
  components: {
    PageFixedFooter,
    quillEditor,
    UploadButton,
    ImageCropper,
    CouponExchangeDialog,
  },
  setup (props) {
    const { editorOption } = useEditor()
    const router = useRouter()
    const route = useRoute()
    const loading = ref(false)
    const formRef = ref(false)
    const formData = reactive({
      name: null,
      image: null,
      avatar: null,
      expType: 'expDay',
      expDay: null,
      expAt: null,
      notice: null,
      showMinute: 3,
      systemRedeem: false,
    })
    const formRules = {
      name: [noEmptyRules()],
      expType: [noEmptyRules()],
      expDay: [noEmptyRules(), isDigitRules()],
      expAt: [noEmptyRules()],
      notice: [noEmptyRules()],
    }
    const uploadDialog = ref(false)
    const avatarChanged = ref(false)
    const shopId = computed(() => store.getters.shop)
    const couponId = computed(() => route.params.id)
    const showDialog = reactive({
      show: false,
      couponId: null,
    })

    const expTypeConfig = [
      {
        label: '領取後可使用天數',
        value: 'expDay',
      },
      {
        label: '特定截止日期',
        value: 'expAt',
      },
    ]

    const loadImg = (img) => {
      formData.img = img
      uploadDialog.value = true
    }

    const getImage = (data) => {
      formData.avatar = data
      avatarChanged.value = true
      uploadDialog.value = false
    }

    const removeOption = () => {
      formData.expDay = null
      formData.expAt = null
    }

    const createCouponExchange = async (payload) => {
      const [res, err] = await CreateCouponExchange({
        shopId: shopId.value,
        ...payload,
      })
      if (err) throw err
      showDialog.show = true
      showDialog.couponId = res.id
      window.$message.success('新增成功!')
    }

    const updateCouponExchange = async (payload) => {
      const [, err] = await UpdateCouponExchange({
        shopId: shopId.value,
        id: couponId.value,
        ...payload,
      })
      if (err) throw err
      window.$message.success('更新商品成功！')
      router.push({ name: 'CouponExchangeSetting' })
    }

    const syncData = (res) => {
      formData.name = res.name
      formData.avatar = res.Image
      formData.expType = res.expType
      formData.expDay = res.expDay
      formData.expAt = res.expAt
      formData.notice = res.notice
      formData.showMinute = res.showMinute || 3
      formData.systemRedeem = res.systemRedeem
    }

    const findCouponExchange = async () => {
      loading.value = true
      const [res, err] = await FindCouponExchange({
        shopId: shopId.value,
        id: couponId.value,
      })
      loading.value = false
      if (err) {
        window.$message.err(err)
        return
      }
      syncData(res)
    }

    const onCancel = () => {
      router.push({
        name: 'CouponExchangeSetting',
      })
    }
    const onSubmit = async () => {
      const pass = await formUtils.checkForm(formRef.value)
      if (pass) {
        const {
          name,
          avatar,
          expType,
          expDay,
          expAt,
          notice,
          showMinute,
          systemRedeem,
        } = formData
        const postBody = {
          name,
          expType,
          expDay,
          notice,
          showMinute,
          expAt: expAt ? formatDateTime(expAt) : null,
          image: avatar?.id || undefined,
          systemRedeem,
        }
        if (couponId.value) {
          delete postBody.expType
          delete postBody.expDay
          delete postBody.expAt
        }
        try {
          loading.value = true
          if (!couponId.value) await createCouponExchange(postBody)
          if (couponId.value) await updateCouponExchange(postBody)
        } catch (error) {
          window.$message.error(error)
          loading.value = false
        }
      }
    }

    const formatDateTime = (time) => {
      return formatDate(time, 'YYYY/MM/DD HH:mm:ss')
    }

    onMounted(() => {
      if (couponId.value) findCouponExchange()
    })

    return {
      loading,
      router,
      formRef,
      formData,
      formRules,
      couponId,
      onCancel,
      onSubmit,
      editorOption,
      uploadDialog,
      loadImg,
      getImage,
      expTypeConfig,
      removeOption,
      formatDateTime,
      showDialog,
    }
  },
})
</script>

<style lang="postcss" scoped>
.form-wrapper {
  @apply px-[32px] py-[24px];
}
.disable-item {
  @apply text-[#606266];
}
</style>
